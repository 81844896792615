@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@eidellev/react-tag-input/dist/index.css';

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 224 71.4% 4.1%;

        --card: 0 0% 100%;
        --card-foreground: 224 71.4% 4.1%;

        --popover: 0 0% 100%;
        --popover-foreground: 224 71.4% 4.1%;

        --primary: 220.9 39.3% 11%;
        --primary-foreground: 210 20% 98%;

        --secondary: 220 14.3% 95.9%;
        --secondary-foreground: 220.9 39.3% 11%;

        --muted: 220 14.3% 95.9%;
        --muted-foreground: 220 8.9% 46.1%;

        --accent: 220 14.3% 95.9%;
        --accent-foreground: 220.9 39.3% 11%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 20% 98%;

        --border: 220 13% 91%;
        --input: 220 13% 91%;
        --ring: 224 71.4% 4.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71.4% 4.1%;
        --foreground: 210 20% 98%;

        --card: 224 71.4% 4.1%;
        --card-foreground: 210 20% 98%;

        --popover: 224 71.4% 4.1%;
        --popover-foreground: 210 20% 98%;

        --primary: 210 20% 98%;
        --primary-foreground: 220.9 39.3% 11%;

        --secondary: 215 27.9% 16.9%;
        --secondary-foreground: 210 20% 98%;

        --muted: 215 27.9% 16.9%;
        --muted-foreground: 217.9 10.6% 64.9%;

        --accent: 215 27.9% 16.9%;
        --accent-foreground: 210 20% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 20% 98%;

        --border: 215 27.9% 16.9%;
        --input: 215 27.9% 16.9%;
        --ring: 216 12.2% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

.tag-input {
    @apply rounded-none border-black;
}

.lock-scroll {
    overflow: hidden;
}

.hamburger {
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
}

.hamburger::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    transform: translateY(-10px);
}

.hamburger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    transform: translateY(10px);
}

.active-hamburger {
    width: 30px;
    height: 3px;
    background-color: transparent;
    border-radius: 5px;
    transform: translateX(-50px);
    transition: all 0.5s ease-in-out;
}

.active-hamburger::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    transform: rotate(45deg) translate(35px, -35px);
}

.active-hamburger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    transform: rotate(-45deg) translate(35px, 35px);
}

.back-button {
    width: 30px;
    height: 3px;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
}

.back-button::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 4px;
    background-color: white;
    border-radius: 0px 5px 5px 0px;
    transition: all 0.5s ease-in-out;
    transform: rotate(45deg) translate(7.5px, 3px);
}

.back-button::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 4px;
    background-color: white;
    border-radius: 0px 5px 5px 0px;
    transition: all 0.5s ease-in-out;
    transform: rotate(-45deg) translate(7.5px, -3px);
}

.sidenav {
    position: fixed;
    top: 5rem;
    right: 100%;
    width: 75%;
    height: 100vh;
    padding-bottom: 6rem;
    background-color: white;
    transition: all 0.5s ease-in-out;
    box-shadow: -1px 5px 8px rgba(0, 0, 0, 0.2) inset;
    z-index: 20;
}

.active-sidenav {
    position: fixed;
    top: 5rem;
    right: 100%;
    width: 75%;
    height: 100vh;
    padding-bottom: 6rem;
    background-color: white;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out;
    box-shadow: -1px 5px 8px rgba(0, 0, 0, 0.2) inset;
    z-index: 20;
    overflow: auto;
}

.shadow {
    position: fixed;
    bottom: -5rem;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0%;
    transition: all 0.5s ease-in-out;
    z-index: 10;
    pointer-events: none;
    overflow: hidden;
}

.active-shadow {
    position: fixed;
    bottom: -5rem;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 25%;
    transition: all 0.5s ease-in-out;
    z-index: 10;
    overflow: hidden;
}

.homepage-button {
    background:
        linear-gradient(white, white) padding-box,
        linear-gradient(to left, rgb(209 213 219), white) border-box;
    border-radius: 8px;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
}

.mobile-button-green {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: #76b949;
    transition: all 0.25s ease-in-out;
}

.mobile-button-red {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: #e6292b;
    transition: all 0.25s ease-in-out;
}

.mobile-button-green:hover,
.mobile-button-red:hover {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: #4fc2eb;
    transition: all 0.25s ease-in-out;
}

.mobile-button-green:disabled,
.mobile-button-red:disabled {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: gray;
    transition: all 0.25s ease-in-out;
}

.mobile-button-black {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: black;
    border: solid 2px white;
    transition: all 0.25s ease-in-out;
}

.mobile-button-black:hover {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    background-color: white;
    border: solid 2px white;
    transition: all 0.25s ease-in-out;
}

.mobile-button-black-border {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    background-color: black;
    border: solid 2px black;
    transition: all 0.25s ease-in-out;
}

.mobile-button-black-border:hover {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    background-color: white;
    border: solid 2px black;
    transition: all 0.25s ease-in-out;
}

.google-login-button {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    background-color: white;
    transition: all 0.25s ease-in-out;
}

.google-login-button:hover {
    height: 3.5rem;
    width: 83.333333%;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    background-color: #4fc2eb;
    transition: all 0.25s ease-in-out;
}

.mobile-search-open {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    transition: all 0.5s ease-in-out;
    transform: translateY(100%);
    background-color: white;
}

.mobile-search {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    transition: all 0.5s ease-in-out;
    background-color: white;
}

.spacer-yellow {
    height: 2px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #f9bb1d;
}

.spacer-white {
    height: 2px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: white;
}

.form-input {
    height: 3rem;
    width: 83.333333%;
    background-color: white;
    color: black;
    padding-left: 12px;
}

.back-button-black {
    border: solid 1px black;
    background-color: white;
    color: black;
    transition: all 100ms ease-in-out;
}

.back-button-black:hover {
    border: solid 1px black;
    background-color: black;
    color: white;
    transition: all 100ms ease-in-out;
}

.back-button-white {
    border: solid 1px white;
    background-color: black;
    color: white;
    transition: all 100ms ease-in-out;
}

.back-button-white:hover {
    border: solid 1px white;
    background-color: white;
    color: black;
    transition: all 100ms ease-in-out;
}

.zoomed-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    transition: all 100ms ease-in-out;
}

.zoomed-image {
    max-width: 90vw;
    max-height: 90vh;
    transition: all 100ms ease-in-out;
}

.element-box:hover {
    background-color: #4fc2eb25;
    border-color: #4fc2eb;
    outline: 2px solid #4fc2eb;
}

.filter-button {
    width: fit-content;
    height: fit-content;
    background-color: #9b2885;
    border-bottom: transparent;
    color: white;
    font-size: large;
    z-index: 1;
}

.filter-box {
    width: 100%;
    height: 0px;
    padding: 0.5rem;
    margin-top: -16px;
    background-color: white;
    border: solid 1px black;
    opacity: 0%;
    transform: translateY(-100%);
    transition: all 100ms ease-in-out;
    z-index: -1;
}

.filter-box-open {
    width: 100%;
    height: fit-content;
    padding: 0.5rem;
    margin-top: -1px;
    background-color: white;
    border: solid 1px black;
    opacity: 100%;
    transform: translateY(0%);
    transition: all 100ms ease-in-out;
    z-index: 0;
}

.mobile-filter {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    transition: all 0.5s ease-in-out;
    background-color: white;
}

.mobile-filter-open {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 30;
    transition: all 0.5s ease-in-out;
    transform: translateY(100%);
    background-color: white;
}

.back-text {
    position: relative;
    font-weight: bold;
    color: white;
    margin-left: 5px;
    opacity: 0%;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
}

.back-text-active {
    position: relative;
    font-weight: bold;
    color: white;
    margin-left: 5px;
    opacity: 100%;
    transform: translateX(0%);
    transition: all 0.5s ease-in-out;
}

.instagram {
    background: radial-gradient(
            circle farthest-corner at 35% 90%,
            #fec564,
            transparent 50%
        ),
        radial-gradient(
            circle farthest-corner at 0 140%,
            #fec564,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 0 -25%,
            #5258cf,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 100% 0,
            #893dc2,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%
        ),
        radial-gradient(
            ellipse farthest-corner at 100% 100%,
            #d9317a,
            transparent
        ),
        linear-gradient(
            #6559ca,
            #bc318f 30%,
            #e33f5f 50%,
            #f77638 70%,
            #fec66d 100%
        );
}
